import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import { FluidImage } from '../components/fluidImage';

const IndexPage = ({}) => {
    const kompetenzen = useKompetenzen();
    return (
        <Layout withFooterMap={false}>
            <SEO title="Kanzlei" />
            <section>
                <FluidImage className="personal-image" name="personal.jpg" />
            </section>
            <section className="intro-text">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Bauer & Dr.Pauly</h1>
                            <p>
                                Seit 2004 betreiben wir unsere überregional aufgestellte Kanzlei mit Sitz in Stuttgart.
                                <br />
                                Wir sind erfahrene Berater unserer Mandanten.
                                <br />
                                Mit höchstem Einsatz, höchster juristischer Qualität, rechtssicher und effizient.
                            </p>
                            <div className="tags">
                                <p>ergebnisorientiert</p>
                                <p>strategisch</p>
                                <p>analytisch</p>
                                <p>wegweisend</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="competent">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Kompetenzen</h2>
                        </div>
                        {kompetenzen.map(kompetenz => (
                            <div className="col-xl-3 col-md-4 col-sm-6 col-6 mb-4">
                                <div key={kompetenz.name} className="competent-box">
                                    <Link to={kompetenz.path}>
                                        <div className="text-center">
                                            <div className="img-box">
                                                <Img className="competent-img" fluid={kompetenz.fluid} />
                                            </div>
                                            <div className="title text-uppercase">{kompetenz.title}</div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="col-12 text-center">
                        <Link className="more-kompetent text-uppercase" to="/kompetenzen">
                            Alle Kompetenzen ansehen
                        </Link>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default IndexPage;

const useKompetenzen = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allFile(filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "kompetenzen" } }) {
                    edges {
                        node {
                            name
                            childImageSharp {
                                fluid(maxWidth: 1600, quality: 90) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                allMarkdownRemark(limit: 1000, sort: { fields: frontmatter___position_indexpage }, filter: { fileAbsolutePath: { regex: "/kompetenzen/" } }) {
                    edges {
                        node {
                            frontmatter {
                                path
                                title
                                image
                                position_indexpage
                            }
                        }
                    }
                }
            }
        `
    );

    const kompetenzen = {};
    // package all kompetenzen that have images
    data.allMarkdownRemark.edges
        .map(edge => ({
            title: edge.node.frontmatter.title,
            path: edge.node.frontmatter.path,
            image: edge.node.frontmatter.image,
        }))
        .filter(element => element.image.length > 0)
        .forEach(element => (kompetenzen[element.image] = element));
    // package all images on top
    data.allFile.edges.forEach(edge => {
        const { node } = edge;
        kompetenzen[node.name].fluid = node.childImageSharp.fluid;
    });

    // and return list of kompetenzen containing all data
    return Object.keys(kompetenzen).map(key => kompetenzen[key]);
};
